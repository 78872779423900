






















import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class TimePicker extends Vue {
  @Prop({ default: () => [] }) readonly initialValue!: Date[];

  @Prop({
    default: () => ({ firstTime: false, secondTime: false }),
  })
  readonly disabled?: { firstTime: boolean, secondTime: boolean };

  value = [
    moment().subtract(1, 'hours').toDate(),
    moment().toDate(),
  ]

  mounted() {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
    this.onInput();
  }

  onInput() {
    this.$emit('input', {
      firstTime: this.value[0],
      secondTime: this.value[1],
    });
  }
}
